<template>
  <div>

    <edit-pool v-if="currentUser" :id="item.id"></edit-pool>

    <nav class="npnav">
      <div class="nav">
        <router-link v-if="prevLink" :to="prevLink" :aria-label="'Previous page in ' + this.$route.meta.tag">&lt;</router-link>
      </div>
      <div class="nav">
        <router-link v-if="nextLink" :to="nextLink" :aria-label="'Next page in ' + this.$route.meta.tag">&gt;</router-link>
      </div>
    </nav>

    <div v-if="Object.keys(item).length==0" class="delayVisibility">
      <h1>404</h1>
      <p>Route not found: {{$route.path}}</p>
    </div>
    <div v-touch:swipe="swipeHandler">


      <h1 v-html="item.title"></h1>

      <div class="label">{{item.date | formatDate('date')}}
        <!--       <template v-if="item.img">
        &#8212; {{item.f}}  {{item.mm}}  {{item.iso}}  {{item.shutter}}
      </template> -->
      </div>
      <compiled-content :input="item.body"></compiled-content>
      <div v-if="item.img" class="pageimage">
        <img :src="item.img" :alt="'Photo of '+item.title">
      </div>
    </div>

  </div>
</template>

<script>
import {
	mapState
} from 'vuex'
import EditPool from '@/components/EditPool';
import CompiledContent from '@/components/CompiledContent';

export default {
	name: 'pool',
	components: {
		EditPool,
		CompiledContent
	},
	props: ['slug'],
	data() {
		return {
			nextLink: false,
			prevLink: false
		}
	},
	beforeMount() {
		this.$store.dispatch('pool/get', this.slug);

		this.setNextPrev();
		if (this.$route.meta.tag) {
			this.$store.dispatch('pool/getByTag', this.$route.meta.tag)
		}
		document.addEventListener("keydown", this.keyEventWatcher)
	},
	beforeDestroy() {
		document.removeEventListener("keydown", this.keyEventWatcher)
	},
	watch: {
		pool() {
			// can't just do this in the item() computed, becasue the getByTag may not have fired yet.
			// Not bothering with prerender here as in Page.vue; google will survive without next/prev links
			this.setNextPrev();
		}
	},
	methods: {
		keyEventWatcher(e) {
			if (document.activeElement.tagName !== 'BODY') return; // don't catch events inside form fields for example
			if (this.prevLink && e.key === "ArrowLeft" || e.keyCode === 37) {
				this.$router.push(this.prevLink)
			}
			if (this.nextLink && e.key === "ArrowRight" || e.keyCode === 39) {
				this.$router.push(this.nextLink)
			}
		},
		swipeHandler(e) {
			if (this.prevLink && e === 'right') {
				this.$router.push(this.prevLink)
			}
			if (this.nextLink && e === 'left') {
				this.$router.push(this.nextLink)
			}
		},
		setNextPrev() {

			// use this.$route.meta.tag and the id to find the next and previous links
			if (this.$route.meta.tag) {
				let sorted = Object.keys(this.pool).filter((k) => {
					return this.pool[k].tags.includes(this.$route.meta.tag)
				}).sort((a, b) => {
					return this.pool[a].date - this.pool[b].date
				})

				// find the current id in sorted; nextLink and prevLink are the adjacent ids
				let curIndex = sorted.indexOf(this.item.id)
				if (curIndex > -1) {
					if (curIndex < sorted.length - 1) {
						this.nextLink = this.pool[sorted[curIndex + 1]].slug + '.html#body'
					}
					if (curIndex > 0) {
						this.prevLink = this.pool[sorted[curIndex - 1]].slug + '.html#body'
					}
				}
			}
		}
	},
	computed: {
		item() {
			let p = {};

			if (isNaN(this.slug)) {
				// URL contains a slug
				p = this.$store.getters['pool/bySlug'](this.slug);
				if (p.title) {
					document.title = p.title;
					this.$nextTick(() => {
						document.dispatchEvent(new Event('prerender-trigger'))
					})
				}
			} else {
				// slug is numeric, assume it's an ID.  This will probably bite me
				p = this.pool[this.slug] || {};
				if (p.slug) {
					// add a "canonical" link to ID-based urls, pointing to the slug url
					let newUrl = this.$route.path.replace(/\d+\.html$/, p.slug + ".html");
					if (!document.querySelector('link[rel="canonical"]')) {
						var temp = document.createElement('div');
						temp.innerHTML = '<link rel="canonical" href="' + newUrl + '" />';
						document.head.appendChild(temp.firstChild);
					}
					this.$router.replace(newUrl)
				}
			}
			return p;
		},
		...mapState('pool', {
			'pool': 'pool'
		}),
		...mapState('user', {
			'currentUser': 'currentUser'
		})
	}
}
</script>

<style>
.pageimage {
	text-align: center;
	max-height: 100vh;
}

@media screen and (max-width: 600px) {

	/* override article margins to go full bleed */
	.pageimage {
		margin-left: -1em;
		margin-right: -1em;
	}
}

.pageimage img {
	max-height: 100vh;
	max-width: 100%;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.npnav {
	margin-left: -3em;
	width: 4em;
	position: absolute;
}

.npnav .nav {
	transition: all 1s;
}

.npnav .nav:hover {}

.npnav a {
	font-family: monospace;
	width: 1.5em;
	height: 1.5em;
	line-height: 1.5em;
	border-radius: 50%;
	margin: 0 0.25em 0.25em;
	display: inline-block;
	text-align: center;
	background-color: #CCC;

	border: none;
	color: #FFF;
	font-weight: bold;
}

.npnav a:hover {
	border: none;
	background-color: #333;
}

@keyframes delayVisibility {
	0% {
		opacity: 0
	}

	98% {
		opacity: 0
	}

	100% {
		opacity: 1
	}
}

.delayVisibility {
	animation-duration: 2s;
	animation-name: delayVisibility;
}

</style>